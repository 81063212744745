import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields },
      },
    }) => (
      <div
        dangerouslySetInnerHTML={{
          __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
        }}
      />
    ),
  },
}

const Maps = ({ data }) => (
  <Layout>
    <SEO title={data.contentfulMapsPage.pageTitle} />
    <PageTitle title={data.contentfulMapsPage.pageTitle} />
    <Container>
      <Row className="my-4">
        <Col className="text-center">
          {documentToReactComponents(
            data.contentfulMapsPage.pageContent.json,
            options
          )}
        </Col>
      </Row>
      <div className="map-container">
        {data.contentfulMapsPage.maps.map((map, index) => (
          <div
            className="text-center"
          >
            <a
              href={map.mapFile.fluid.src}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                fixed={map.mapThumbnail.fixed}
                alt={map.mapTitle}
                className="rounded"
                style={{
                  width: map.mapThumbnail.fixed.width,
                  height: map.mapThumbnail.fixed.height,
                }}
              />
              <div className="my-2">
                <div className="mb-2">
                  <p style={{ fontSize: "1rem", color: "black" }}>
                    {documentToReactComponents(
                      map.mapDescription?.json,
                      options
                    )}
                  </p>
                </div>
                <Button className="btn  trcButton btn-block">
                  {map.mapTitle}
                </Button>
              </div>
            </a>
          </div>
        ))}
      </div>
    </Container>
    <Container>
      <hr />
      <Row>
        <Partners />
      </Row>
      <hr />
      <Row>
        <Sponsors />
      </Row>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    contentfulMapsPage {
      pageTitle
      pageContent {
        json
      }
      maps {
        mapTitle
        mapThumbnail {
          fixed {
            srcSet
            height
            width
          }
        }
        mapFile {
          fluid {
            src
          }
        }
        mapDescription {
          json
        }
      }
    }
  }
`

export default Maps
